import { render, staticRenderFns } from "./404NotFound.vue?vue&type=template&id=2bc65ea2&scoped=true&"
import script from "./404NotFound.vue?vue&type=script&lang=ts&"
export * from "./404NotFound.vue?vue&type=script&lang=ts&"
import style0 from "./404NotFound.vue?vue&type=style&index=0&id=2bc65ea2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc65ea2",
  null
  
)

export default component.exports